@tailwind base;
@tailwind components;
@tailwind utilities;


.text-with-image {
    position: relative;
    display: inline-block;
    font-size: 15rem;
    /* Increase font size */
    font-weight: bold;
    color: transparent;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    background-size: cover;
}


/* src/index.css */
/* src/index.css */

@keyframes slide-in-right {
    0% {
        transform: translateX(-50%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

@keyframes slide-in-left {
    0% {
        transform: translateX(50%);
        opacity: 0;
    }

    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.hello-world-left.animate-left {
    animation: slide-in-left 2s forwards;
}

.hello-world-right.animate-right {
    animation: slide-in-right 2s forwards;
}




/* src/index.css or src/App.css */

@keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-10px);
    }
    100% {
      transform: translatey(0px);
    }
  }
  
  .floating {
    animation: float 5s ease-in-out infinite;
  }
  

  /* src/index.css or src/App.css */

.card {
    width: 280px;
    height: 280px;
    background: white;
    border-radius: 32px;
    padding: 3px;
    position: relative;
    box-shadow: #604b4a30 0px 70px 30px -50px;
    transition: all 0.5s ease-in-out;
  }
  
  .card .mail {
    position: absolute;
    right: 8rem;
    top: 1.4rem;
    background: transparent;
    border: none;
  }
  
  .card .mail svg {
    stroke: #fbb9b6;
    stroke-width: 3px;
  }
  
  .card .mail svg:hover {
    stroke: #f55d56;
  }
  
  .card .profile-pic {
    position: absolute;
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    top: 3px;
    left: 3px;
    border-radius: 29px;
    z-index: 1;
    border: 0px solid #fbb9b6;
    overflow: hidden;
    transition: all 0.5s ease-in-out 0.2s, z-index 0.5s ease-in-out 0.2s;
  }
  
  .card .profile-pic img {
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: 0px 50px;
    transition: all 0.5s ease-in-out 0s;
  }
  
  .card .bottom {
    position: absolute;
    bottom: 3px;
    left: 3px;
    right: 3px;
    background: #fbb9b6;
    top: 80%;
    border-radius: 29px;
    z-index: 2;
    box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px inset;
    overflow: hidden;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
  }
  
  .card .bottom .content {
    position: absolute;
    bottom: 0;
    left: 1.5rem;
    right: 1.5rem;
    height: 160px;
  }
  
  .card .bottom .content .name {
    display: block;
    font-size: 1.2rem;
    color: white;
    font-weight: bold;
  }
  
  .card .bottom .content .about-me {
    display: block;
    font-size: 0.9rem;
    color: white;
    margin-top: 1rem;
  }
  
  .card .bottom .bottom-bottom {
    position: absolute;
    bottom: 1rem;
    left: 1.5rem;
    right: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .card .bottom .bottom-bottom .social-links-container {
    display: flex;
    gap: 1rem;
  }
  
  .card .bottom .bottom-bottom .social-links-container svg {
    height: 20px;
    fill: white;
    filter: drop-shadow(0 5px 5px rgba(165, 132, 130, 0.1333333333));
  }
  
  .card .bottom .bottom-bottom .social-links-container svg:hover {
    fill: #f55d56;
    transform: scale(1.2);
  }
  
  .card .bottom .bottom-bottom .button {
    background: white;
    color: #fbb9b6;
    border: none;
    border-radius: 20px;
    font-size: 0.6rem;
    padding: 0.4rem 0.6rem;
    box-shadow: rgba(165, 132, 130, 0.1333333333) 0px 5px 5px 0px;
  }
  
  .card .bottom .bottom-bottom .button:hover {
    background: #f55d56;
    color: white;
  }
  
  .card.hovered {
    border-top-left-radius: 55px;
  }
  
  .card.hovered .bottom {
    top: 20%;
    border-radius: 80px 29px 29px 29px;
    transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) 0.2s;
  }
  
  .card.hovered .profile-pic {
    width: 0px;
    height: 0px;
    aspect-ratio: 1;
    top: 10px;
    left: 10px;
    border-radius: 50%;
    z-index: 3;
    border: 7px solid #fbb9b6;
    box-shadow: rgba(96, 75, 74, 0.1882352941) 0px 5px 5px 0px;
    transition: all 0.5s ease-in-out, z-index 0.5s ease-in-out 0.1s;
  }
  
  .card.hovered .profile-pic:hover {
    transform: scale(1.3);
    border-radius: 0px;
  }
  
  .card.hovered .profile-pic img {
    transform: scale(2.5);
    object-position: 0px 25px;
    transition: all 0.5s ease-in-out 0.5s;
  }
  
  .card.hovered .profile-pic svg {
    transform: scale(2.5);
    transition: all 0.5s ease-in-out 0.5s;
  }
  